import React from "react";
import { FaTimes } from "react-icons/fa";

const Index = ({ selectedNotification }: { selectedNotification: any }) => {
  return (
    <div>
      <div className="details-head">
        <h2 className="details-title">{selectedNotification.details.title}</h2>
        {/* <p className="details-close">
          <FaTimes className="font-bold" size={18} color="#FF5252" />{" "}
          <span>Close</span>
        </p> */}
      </div>
      <div className="details-body">
        <p className="details-date">{selectedNotification.details.date}</p>
        <img
          src={selectedNotification.details.image}
          alt="Notification"
          className="details-image"
        />
        <p className="details-content">
          {selectedNotification.details.content}
        </p>
      </div>
    </div>
  );
};

export default Index;
