import { configureStore } from "@reduxjs/toolkit";
import completedSchedule from "./slice/schedule/completed";
import detail from "./slice/schedule/detail";
import mark from "./slice/schedule/mark-as-completed";
import upcomingSchedule from "./slice/schedule/upcoming";
import report from "./slice/schedule/report";
import reviewCheck from "./slice/schedule/review-check";
import review from "./slice/schedule/review";
import reviewUpdate from "./slice/schedule/review-update";
import profileSlice from "./slice/profile/profile";
import countrySlice from "./slice/profile/countryAndStates";
import teachersListSlice from "./slice/teacher/teachers-lists";
import languagesTaught from "./slice/language/languages";
import teacherAvailabilities from "./slice/teacher/availability";
import balance from "./slice/payment";
import blogs from "./slice/blog";
import chatList from "./slice/chat/chatList";
import prevChats from "./slice/chat/prevMessages";

export const store = configureStore({
  reducer: {
    completedSchedule,
    detail,
    mark,
    upcomingSchedule,
    report,
    reviewCheck,
    review,
    reviewUpdate,
    profileSlice,
    countrySlice,
    teachersListSlice,
    languagesTaught,
    teacherAvailabilities,
    balance,
    blogs,
    chatList,
    prevChats
  },
  // middleware: (getDefaultMiddleware) => [
  //   ...getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
  // ],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
