import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "@/services/api-request";

// Define interfaces
export interface Chat {
  id: string;
  content: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  teacherId: string;
  senderUserType: string;
  receiver: string;
  sender: string;
  lastMessage: {
    createdAt: string;
    content: string;
    id: string;
  };
}

interface ChatState {
  data: Chat[];
  loading: boolean;
  error: string | null;
}

const initialState: ChatState = {
  data: [],
  loading: false,
  error: null,
};

export const chatListFetch = createAsyncThunk<
  Chat[],
  { token: string },
  { rejectValue: string }
>("chatList/fetch", async ({ token }, { rejectWithValue }) => {
  try {
    const { response } = await GET(`/messages`, token);
    return [...response.data.result].sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt).getTime() -
        new Date(a?.lastMessage?.createdAt).getTime()
    );
  } catch (err: any) {
    console.warn(err);
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

// Chat slice
const chatListSlice = createSlice({
  name: "chatList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chatListFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        chatListFetch.fulfilled,
        (state, action: PayloadAction<Chat[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        chatListFetch.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Unknown error";
        }
      );
  },
});

export default chatListSlice.reducer;
