import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

export interface Blogs {
  id: string;
  slug: string;
  title: string;
  date_published: string;
  author: string;
  description: string;
  image_url: string;
}

interface blogsState {
  data: Blogs[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: blogsState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchBlogs = createAsyncThunk(
  "blog/fetchBlogs",
  async ({ token }: { token: string }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://www.pluritongues.com/api/featured-blogs`,
        {
          // method: "GET",
          // mode: "no-cors",
          // headers: {
          //   // Authorization: `Bearer ${token}`,
          //   // "Content-Type": "application/json",
          // },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch blogs.");
      }

      const data = await response.json();

      return data;
    } catch (err: any) {
      console.warn(err);
      const errorMessage =
        err.message || "An error occurred while fetching blogs.";
      return rejectWithValue(errorMessage);
    }
  }
);

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBlogs.fulfilled,
        (state, action: PayloadAction<Blogs[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(fetchBlogs.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default blogsSlice.reducer;
