import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  SignIn,
  Home,
  Activties,
  Activity,
  Profile,
  Teachers,
  TeacherPage,
  Schedule,
  ScheduleDetails,
  CompletedScheduleDetails,
  NotFoundPage,
  Partners,
  Report,
  Review,
  Dispute,
  Notification,
  AddMoney,
  PayWithCard,
  ComingSoon,
} from "@/pages";

import ProtectedRoute from "@/components/ProtectedRoute";
import ForgotPassword from "@/pages/forgot-password";
import GotMail from "@/pages/got-mail";
import NewPassword from "@/pages/new-password";
import Register from "@/pages/register";

export const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<Register />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/got-mail" element={<GotMail />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partners"
          element={
            <ProtectedRoute>
              <Partners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/teachers"
          element={
            <ProtectedRoute>
              <Teachers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/teachers/:teacher_id"
          element={
            <ProtectedRoute>
              <TeacherPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/activity"
          element={
            <ProtectedRoute>
              <Activties />
            </ProtectedRoute>
          }
        />
        <Route
          path="/activity/:activity_id"
          element={
            <ProtectedRoute>
              <Activity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule"
          element={
            <ProtectedRoute>
              <Schedule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule/upcoming/:lesson_id"
          element={
            <ProtectedRoute>
              <ScheduleDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule/completed/:lesson_id"
          element={
            <ProtectedRoute>
              <CompletedScheduleDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule/completed/:lesson_id/report"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule/completed/:lesson_id/review"
          element={
            <ProtectedRoute>
              <Review />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule/completed/:lesson_id/dispute"
          element={
            <ProtectedRoute>
              <Dispute />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notification"
          element={
            <ProtectedRoute>
              <Notification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/payment"
          element={
            <ProtectedRoute>
              <AddMoney />
            </ProtectedRoute>
          }
        />
        <Route
          path="/teachers/direct-payment"
          element={
            <ProtectedRoute>
              <PayWithCard />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};
