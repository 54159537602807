import { useCallback, useEffect } from "react";
import "./styles.scss";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { FaTimes } from "react-icons/fa";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { prevMessagesFetch } from "@/redux/slice/chat/prevMessages";
import { useAuth } from "@/contexts/auth-context";
import { isTimeOver24HoursAgo } from "@/utils/function";

interface IChat {
  teacherId: string;
  content: string;
  senderUserType: string;
  receiver: string;
  sender: string;
  id: string;
  status?: "pending" | "sent" | "failed";
  profilePicture: string;
  firstName: string;
  lastName: string;
  lastMessage: {
    createdAt: string;
    content: string;
    id: string;
  };
}

interface IChats {
  selectedMessage: IChat;
  setSelectedMessage: (a: any) => void;
}

const Chat = ({ selectedMessage, setSelectedMessage }: IChats) => {
  const { token, details } = useAuth();
  const dispatch = useAppDispatch();

  const chatFetch = useCallback(() => {
    dispatch(
      prevMessagesFetch({ token: token!, id: selectedMessage.teacherId })
    );
  }, [token, selectedMessage.teacherId, dispatch]);

  useEffect(() => {
    chatFetch();
  }, [chatFetch]);

  const { data, loading, error } = useAppSelector((state) => state.prevChats);

  return (
    <div className="notification-details">
      <div className="chat-container">
        <div className="details-head">
          <h2 className="details-title">{`${selectedMessage.firstName} ${selectedMessage.lastName}`}</h2>
          {/* <p onClick={() => setSelectedMessage("")} className="details-close">
            <FaTimes className="font-bold" size={18} color="#FF5252" />
            <span>Close</span>
          </p> */}
        </div>

        {loading ? (
          <p>Loading</p>
        ) : (
          <div className="chat-body">
            {data.map((chat, index) => {
              return (
                <div className="chat-item-container" key={index}>
                  <div className="chat-date">
                    {isTimeOver24HoursAgo(chat?.createdAt)
                      ? new Date(chat?.createdAt).toLocaleDateString()
                      : new Date(chat?.createdAt).toLocaleTimeString()}
                  </div>
                  <div
                    className={`chat-item ${
                      chat.sender === "me" ? "sent" : "received"
                    }`}
                  >
                    <div className="image-container">
                      <img
                        src={chat.profilePicture}
                        alt={chat.sender}
                        className="chat-avatar"
                      />
                    </div>
                    <div className="chat-content">
                      <p className="chat-sender">
                        {chat.teacherId === details?.id
                          ? "You"
                          : `${selectedMessage.firstName} ${selectedMessage.lastName}`}
                      </p>
                      <p className="chat-message">{chat.content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="chat-footer">
          <textarea
            cols={3}
            rows={2}
            className="chat-input"
            placeholder="Your Message"
          />
          <button className="chat-send">
            <PiPaperPlaneRightFill size={30} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
