import React, { useEffect, useState } from "react";
import { BookingCalendar } from "./booking-calendar";
import DropdownMenu from "@/components/Dropdown";
import { PiListChecksLight, PiTrash } from "react-icons/pi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { teacherAvailabilityDataFetch } from "@/redux/slice/teacher/availability";
import { useAuth } from "@/contexts/auth-context";
import {
  convertDateTimeArray,
  formatDate,
  generateTimeSlots,
} from "@/utils/function";
import "./styles.scss";
import { toast } from "sonner";
import { useLessonContext } from "@/contexts/lesson-context";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

const DateSelect: React.FC = () => {
  const [timeSelected, setTimeSelected] = useState<string>();
  const [showTimeSlot, setShowTimeSlot] = useState<boolean>(false);
  const { values, updateValues } = useLessonContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [dateSelected, setDateSelected] = useState<
    { date: string; time: string }[]
  >([]);
  const [dayTime, updateDayTime] = useState<Date>(new Date());

  const navigate = useNavigate();

  const handleSelect = (value: string) => {
    onDateClick("time", value);
    setTimeSelected(value);
  };

  const { token } = useAuth();
  const dispatch = useAppDispatch();

  const fetch = () => {
    dispatch(
      teacherAvailabilityDataFetch({
        token: token!,
        id: values.marchantId,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  const { data, error, loading } = useAppSelector(
    (state) => state.teacherAvailabilities
  );

  const checkdayTime = (value: Date | string): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return value < today;
  };

  const onDateClick = (type: string, e: Date | string) => {
    console.warn(e);
    if (checkdayTime(e)) {
      toast.warning(
        "You cannot select a past date, Please select a later date"
      );
    } else if (dateSelected.length === values?.quantity) {
      setShowTimeSlot(false);
    } else {
      setDateSelected((prevDates) => {
        let newDates = [...prevDates];

        if (type === "day") {
          updateDayTime(new Date(e));
        } else if (type === "time") {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          !dayTime
            ? ""
            : newDates.push({
                date: dayTime.toLocaleDateString(),
                time: e.toLocaleString(),
              });
        }

        return newDates;
      });

      if (type === "day") {
        setShowTimeSlot(true);
      } else {
        setShowTimeSlot(false);
      }
    }
  };

  const _continueHandler = () => {
    if (dateSelected.length !== values.quantity) return;
    updateValues({ ...values, timeSlots: convertDateTimeArray(dateSelected) });
    const params = new URLSearchParams(searchParams);
    params.set("class", values.quantity.toString());
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const _deleteHandler = (index: number) => {
    setDateSelected((prevDates) => {
      let newDates = [...prevDates];

      if (index >= 0 && index < newDates.length) {
        newDates.splice(index, 1);
      }

      return newDates;
    });
  };
  return (
    <div className="date_select--container">
      {loading ? (
        <p>Loading</p>
      ) : (
        <div>
          {data ? (
            <BookingCalendar
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
              data={data || []}
              onDateClick={onDateClick}
            />
          ) : null}
          {
            <div className="date_select--time">
              <DropdownMenu
                options={generateTimeSlots(
                  data?.available?.availability ?? [],
                  values.duration,
                  dateSelected,
                  dayTime
                )}
                onSelect={handleSelect}
                placeholder="Pick Time"
                value={timeSelected}
              />
            </div>
          }
          <p className="date_select--note">Based on your current time zone</p>
          <div className="date_select--footer">
            <div className="date_select--status">
              <PiListChecksLight />
              <p>{`${dateSelected.length}/${values.quantity} Lesson Date & Time Set`}</p>
            </div>
          </div>

          <div className="selected-dates-container">
            {dateSelected.map((item, index) => {
              return (
                <div className="selected-dates-container-inner">
                  <p>
                    {formatDate(new Date(item.date))}, {item.time}
                  </p>
                  <PiTrash
                    onClick={() => _deleteHandler(index)}
                    color="red"
                    size={24}
                  />
                </div>
              );
            })}
          </div>

          <button
            onClick={() => _continueHandler()}
            disabled={
              dateSelected.length < values.quantity ||
              dateSelected.length > values.quantity
                ? true
                : false
            }
            className="enrolment-btn"
          >
            continue
          </button>
        </div>
      )}
    </div>
  );
};

export default DateSelect;
