import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "@/services/api-request";

// Interfaces
export interface Message {
  content: string;
  createdAt: string;
  teacherId: string;
  senderUserType: string;
  receiver: string;
  sender: string;
  id: string;
  status?: "pending" | "sent" | "failed";
  profilePicture: string;
  lastMessage: {
    createdAt: string;
    content: string;
    id: string;
  };
}

interface PrevMessagesState {
  data: Message[];
  loading: boolean;
  error: string | null;
}

const initialState: PrevMessagesState = {
  data: [],
  loading: false,
  error: null,
};

export const prevMessagesFetch = createAsyncThunk<
  Message[],
  { token: string; id: string },
  { rejectValue: string }
>("prevMessages/fetch", async ({ token, id }, { rejectWithValue }) => {
  try {
    const { response } = await GET(`/messages/${id}`, token);
    return response.data.result;
  } catch (err: any) {
    console.warn(err);
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

// Slice
const prevChatsSlice = createSlice({
  name: "prevChats",
  initialState,
  reducers: {
    setNewMessage: (state, action: PayloadAction<Message>) => {
      state.data.push(action.payload);
    },
    updateMessageStatus: (
      state,
      action: PayloadAction<{ id: string; status: "sent" | "failed" }>
    ) => {
      const message = state.data.find((msg) => msg.id === action.payload.id);
      if (message) {
        message.status = action.payload.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(prevMessagesFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        prevMessagesFetch.fulfilled,
        (state, action: PayloadAction<Message[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(prevMessagesFetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      });
  },
});

export const { setNewMessage, updateMessageStatus } = prevChatsSlice.actions;
export default prevChatsSlice.reducer;
