import Button from "@/components/Button";
import Icon from "@/components/Icon";
import { useState, useRef, useMemo } from "react";
import { Verdict } from "../../Verdict";
import { SingleWordMatchQuestion as SingleWordMatchQuestionType } from "@/types/activity";
import "./styles.scss";

export const SingleWordMatchQuestion = ({
  question,
  handleNextQuestion,
}: {
  question: SingleWordMatchQuestionType;
  handleNextQuestion: () => void;
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const answers = question.answer;

  const [currentQuestion, currentAnswer] = useMemo(() => {
    const cQ = question.question;
    const cA = question.options.find((o) => answers.includes(o.id));

    return [cQ, cA];
  }, [question.question, question.options, answers]);

  const selectedAnswer = useMemo(
    () => question.options.find((option) => option.id === selectedOptionId),
    [selectedOptionId, question.options]
  );

  const is_answer = useMemo(() => {
    if (!currentAnswer || !selectedOptionId) return false;
    if (currentAnswer.id === selectedOptionId) return true;
    else return false;
  }, [selectedOptionId, currentAnswer]);

  const handleClickOption = (optionId: string) => {
    setSelectedOptionId(optionId);
  };

  const handleNext = () => {
    if (is_answer) handleNextQuestion();
    setSelectedOptionId(null);
  };

  return (
    <div className="question_container" data-type={question.activityType}>
      <div className="top">
        <h1>{question.instruction}</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="middle">
        <SingleWordMatchQuestionQuestion
          question={currentQuestion}
          answer={selectedAnswer}
          show_answer={!!selectedOptionId}
          is_answer={is_answer}
        />
      </div>
      <div className="options">
        {question.options.map((option) => (
          <SingleWordMatchOption
            onClick={handleClickOption}
            option={option}
            key={option.id}
          />
        ))}
      </div>

      <Verdict
        is_answer={is_answer}
        answer={currentAnswer?.text ?? ""}
        open={!!selectedOptionId}
        onClose={() => setSelectedOptionId(null)}
        handleNext={handleNext}
      />
    </div>
  );
};

const SingleWordMatchQuestionQuestion = ({
  question,
  answer,
  show_answer,
  is_answer,
}: {
  question?: SingleWordMatchQuestionType["question"];
  answer?: SingleWordMatchQuestionType["options"][number];
  show_answer: boolean;
  is_answer: boolean;
}) => {
  return (
    <div
      className="question_container"
      data-show-answer={String(show_answer)}
      data-correct={String(is_answer === true)}
      data-wrong={String(is_answer === false)}
    >
      <div className="question">
        {question?.text && question.text}
        {question?.audioUrl && <AudioButton audioUrl={question.audioUrl} />}
      </div>
      {answer && show_answer && <div className="answer">{answer.text}</div>}
    </div>
  );
};

const SingleWordMatchOption = ({
  option,

  onClick,
}: {
  option: SingleWordMatchQuestionType["options"][number];

  onClick: (option_id: string) => void;
}) => {
  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      onClick={() => {
        onClick(option.id);
      }}
    >
      <p>{option.text}</p>
    </Button>
  );
};

function AudioButton({ audioUrl }: { audioUrl: string }) {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const playAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    } else audioRef.current?.play();
  };
  return (
    <Button variant="neutral" className="audio_container" onClick={playAudio}>
      <Icon name="audio" />
      <audio
        src={audioUrl}
        ref={audioRef}
        onPlay={() => setIsAudioPlaying(true)}
        onPause={() => setIsAudioPlaying(false)}
      ></audio>
    </Button>
  );
}
