import { ILesson } from "@/types/schedules";

// Formate the time remaining
export const formatDateString = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  return formatter.format(date);
};

export function formatStringtoDate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
}

type TimeSlot = `${string}${"am" | "pm"}`;

export const formatTimeSlot = (time: Date): TimeSlot => {
  const h = time.getHours();
  const hour = (h === 12 ? 12 : h % 12).toString().padStart(2, "0");
  const minute = time.getMinutes().toString().padStart(2, "0");
  const meridian = h >= 12 ? "pm" : "am";
  return `${hour}:${minute}${meridian}`;
};

export function isLessonInProgress<T extends { time: ILesson["time_slot"] }>(
  lesson: T
): boolean {
  const { time } = lesson;

  const startTime = new Date(time.start);
  const endTime = new Date(time.end);
  const now = new Date();

  return now >= startTime && now <= endTime;
}

export function isLessonInPast<T extends { time: ILesson["time_slot"] }>(
  lesson: T
): boolean {
  const { time } = lesson;

  const endTime = new Date(time.end);

  const now = new Date();

  return now > endTime;
}

export const timeDifference = (targetDate: string): string => {
  const now: Date = new Date();
  const target: Date = new Date(targetDate);

  // Calculate the difference in milliseconds
  const differenceInMs: number = target.getTime() - now.getTime();

  // If the difference is negative or zero, the target date has passed or is now
  if (differenceInMs <= 0) {
    return "";
  }

  // Convert milliseconds to total seconds
  const differenceInSeconds: number = differenceInMs / 1000;

  // Calculate the remaining days, hours, minutes, and seconds
  const days: number = Math.floor(differenceInSeconds / 86400);
  const hours: number = Math.floor((differenceInSeconds % 86400) / 3600);
  const mins: number = Math.floor((differenceInSeconds % 3600) / 60);
  const secs: number = Math.floor(differenceInSeconds % 60);

  // Return formatted time
  if (days > 0) {
    return `${days} day(s)`;
  } else if (hours > 0) {
    return `${hours} hour(s) ${mins} min(s)`;
  } else if (mins > 0) {
    return `${mins} min(s) ${secs} sec(s)`;
  } else {
    return `${secs} sec(s)`;
  }
};

export const removeMultipleKeys = (keys: string[]): void => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export function parseJSON<T>(value: string | null): T | null {
  try {
    return value ? (JSON.parse(value) as T) : null;
  } catch {
    return null;
  }
}

export const isMoreThan48HoursAgo = (time: Date | string): boolean => {
  const now = new Date();
  const givenTime = typeof time === "string" ? new Date(time) : time;
  const fortyEightHoursAgo = new Date(now.getTime() - 48 * 60 * 60 * 1000);

  return givenTime < fortyEightHoursAgo;
};
export function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number
): T {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return function (...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  } as T;
}

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const currencyFormat = (
  number: number,
  locale: "en-US" = "en-US",
  currency = "USD"
) => {
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    number
  );
};
interface CheckBalanceResult {
  result: boolean;
  possibleAmountToAdd?: number;
}

export const checkBalance = (
  userBalance: number,
  amount: number
): CheckBalanceResult => {
  const total = userBalance + amount;

  if (total > 500) {
    return {
      result: true,
      possibleAmountToAdd: 500 - userBalance,
    };
  } else {
    return {
      result: false,
    };
  }
};

interface ScheduleSlot {
  start: string; // ISO string or Date string
  end: string; // ISO string or Date string
}

interface SelectedSlot {
  date: string; // ISO string or Date string
  time: string; // Time slot like "9:00am to 9:30am"
}

interface TimeSlots {
  value: string;
  label: string;
}

interface ScheduleSlot {
  start: string;
  end: string;
}

interface SelectedSlot {
  date: string; // ISO string or Date string
  time: string; // Time slot like "9:00am to 9:30am"
}

interface TimeSlots {
  value: string;
  label: string;
}

export const generateTimeSlots = (
  schedule: ScheduleSlot[],
  intervalMinutes: number,
  selectedSlots: SelectedSlot[],
  day: Date | null
): TimeSlots[] => {
  const timeSlots: TimeSlots[] = [];
  if (!day) {
    return timeSlots;
  }

  const formatTime = (hour: number, minute: number): string => {
    const ampm = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${formattedHour}:${formattedMinute}${ampm}`;
  };

  const today = new Date();
  const isToday = today.toDateString() === day.toDateString();

  const minStartTime = new Date(day);
  minStartTime.setHours(0, 0, 0, 0); // Start of the day
  const maxEndTime = new Date(day);
  maxEndTime.setHours(23, 0, 0, 0); // End of the day

  schedule.forEach((slot) => {
    const start = new Date(slot.start);
    const end = new Date(slot.end);

    if (start.toDateString() === day.toDateString()) {
      const adjustedStart = new Date(
        Math.max(start.getTime(), minStartTime.getTime())
      );
      const adjustedEnd = new Date(
        Math.min(end.getTime(), maxEndTime.getTime())
      );

      let currentDateTime: Date;

      if (isToday) {
        currentDateTime = new Date(today);
        currentDateTime.setMinutes(
          Math.ceil(today.getMinutes() / intervalMinutes) * intervalMinutes
        );
        currentDateTime.setSeconds(0);
        currentDateTime.setMilliseconds(0);
      } else {
        currentDateTime = adjustedStart;
      }

      if (currentDateTime < adjustedStart) {
        currentDateTime = adjustedStart;
      }

      while (currentDateTime < adjustedEnd) {
        const currentHour = currentDateTime.getHours();
        const currentMinute = currentDateTime.getMinutes();
        const startTime = formatTime(currentHour, currentMinute);

        const nextTime = new Date(
          currentDateTime.getTime() + intervalMinutes * 60000
        );

        if (nextTime > adjustedEnd) {
          break;
        }

        const endTime = formatTime(nextTime.getHours(), nextTime.getMinutes());
        const timeSlot = `${startTime} to ${endTime}`;

        const isSlotSelected = selectedSlots.some(
          (selectedSlot) =>
            new Date(selectedSlot.date).toDateString() ===
              start.toDateString() && selectedSlot.time === timeSlot
        );

        if (!isSlotSelected) {
          timeSlots.push({
            value: timeSlot,
            label: `${timeSlot}`,
          });
        }

        currentDateTime = nextTime;
      }

      const remainingTime = adjustedEnd.getTime() - currentDateTime.getTime();
      if (remainingTime >= intervalMinutes * 60000) {
        const lastStartTime = formatTime(
          adjustedEnd.getHours() - intervalMinutes / 60,
          0
        );
        const lastEndTime = formatTime(
          adjustedEnd.getHours(),
          adjustedEnd.getMinutes()
        );
        const lastTimeSlot = `${lastStartTime} to ${lastEndTime}`;

        const isLastSlotSelected = selectedSlots.some(
          (selectedSlot) =>
            new Date(selectedSlot.date).toDateString() ===
              start.toDateString() && selectedSlot.time === lastTimeSlot
        );

        if (!isLastSlotSelected) {
          timeSlots.push({
            value: lastTimeSlot,
            label: `${lastTimeSlot}`,
          });
        }
      }
    }
  });

  return timeSlots;
};

export const formatDate = (date: Date): string => {
  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const ordinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${month}. ${day}${ordinalSuffix(day)}, ${year}`;
};

export const generateTimeSlotsForDates = (
  dates: { start: string; end: string }[],
  day: string | Date
) => {
  const specifiedDay = new Date(day).getDay();

  const formatTime = (dateStr: string) => {
    const date = new Date(dateStr);
    const localDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    ); // Adjust for local time
    const hour = localDate.getHours();
    const minute = localDate.getMinutes();
    const ampm = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${formattedHour}:${formattedMinute}${ampm}`;
  };

  for (const dateObj of dates) {
    const startDate = new Date(dateObj.start);
    const endDate = new Date(dateObj.end);

    if (
      startDate.getDay() === specifiedDay &&
      endDate.getDay() === specifiedDay
    ) {
      return [
        {
          startTime: formatTime(dateObj.start),
          endTime: formatTime(dateObj.end),
        },
      ];
    }
  }

  return [];
};
export const isTimeOver24HoursAgo = (timestamp: string | Date): boolean => {
  const currentTime = new Date();
  const providedTime = new Date(timestamp);

  // Ensure the provided timestamp is a valid date
  if (isNaN(providedTime.getTime())) {
    throw new Error("Invalid timestamp provided");
  }

  const timeDifference = currentTime.getTime() - providedTime.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  return hoursDifference >= 24;
};

export const checkEqual = (name1: string, name2: string): boolean => {
  const formatName = (name: string) =>
    name
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z]/g, ""); // Remove non-alphabetic characters

  const formattedName1 = formatName(name1);
  const formattedName2 = formatName(name2);

  return formattedName1 === formattedName2;
};

export interface DateTimeInput {
  date: string;
  time: string;
}

export interface ConvertedDateTime {
  start: string;
  end: string;
}

export const convertDateTimeArray = (
  inputArray: DateTimeInput[]
): ConvertedDateTime[] => {
  return inputArray.map(({ date, time }) => {
    if (!date || !time) {
      throw new Error("Invalid input: date and time are required.");
    }

    const [startTime, endTime] = time.split(" to ");
    if (!startTime || !endTime) {
      throw new Error(
        `Invalid time format: "${time}". Expected format: "HH:MMam/pm to HH:MMam/pm".`
      );
    }

    // Convert time to 24-hour format
    const convertTo24Hour = (timeStr: string): string => {
      const match = timeStr.match(/^(\d{1,2}):(\d{2})(am|pm)$/i);
      if (!match) {
        throw new Error(
          `Invalid time format: "${timeStr}". Expected format: "HH:MMam/pm".`
        );
      }

      let [hours, minutes, modifier] = match.slice(1);
      let hour = parseInt(hours, 10);

      if (modifier.toLowerCase() === "pm" && hour !== 12) {
        hour += 12;
      }
      if (modifier.toLowerCase() === "am" && hour === 12) {
        hour = 0;
      }

      return `${hour.toString().padStart(2, "0")}:${minutes}:00`;
    };

    const dateString = new Date(date).toISOString().split("T")[0];

    const startDateTime = new Date(
      `${dateString}T${convertTo24Hour(startTime)}`
    );
    const endDateTime = new Date(`${dateString}T${convertTo24Hour(endTime)}`);

    // Adjust time by adding one day (24 hours in milliseconds)
    const adjustedStart = new Date(
      startDateTime.getTime() + 24 * 60 * 60 * 1000
    );
    const adjustedEnd = new Date(endDateTime.getTime() + 24 * 60 * 60 * 1000);

    return {
      start: adjustedStart.toISOString(),
      end: adjustedEnd.toISOString(),
    };
  });
};
