import { Icon } from "@/components";
import "./styles.scss";
import { PaymentModal } from "@/pages/add-money/PaymentMethod";
import { useState } from "react";
import { currencyFormat } from "@/utils/function";
import Switch from "react-switch";
import { useLanguageContext } from "@/contexts/language-context";

interface Props {
  balance: number;
}

export default function Wallet({ balance }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { setShow, show } = useLanguageContext();

  const _toggleHandler = async () => {
    try {
      const newToggle = !show;
      setShow(newToggle);

      const toggleString = JSON.stringify(newToggle);

      await localStorage.setItem("toggle", toggleString);
    } catch (error) {
      // console.error("Error handling toggle value:", error);
    }
  };

  return (
    <div className="wallet_container">
      <label className="balance-container">
        <h1 className="hide_balance">Hide Wallet Balance</h1>
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#169fed"
          onChange={() => _toggleHandler()}
          checked={show}
          onHandleColor="#fff"
        />
      </label>
      <div className="wallet">
        <div className="top">
          <Icon name="wallet" />
          <span>Wallet Balance</span>
        </div>
        <div className="main">
          <div className="row">
            <h1 className="left">
              {show ? "******" : currencyFormat(balance)}
            </h1>
            <button
              className="plusCircleButton"
              onClick={() => setIsOpen(true)}
            >
              <Icon name="plus_in_circle" />
            </button>
          </div>
        </div>
      </div>
      <PaymentModal setIsOpen={setIsOpen} isOpen={isOpen} balance={balance} />
    </div>
  );
}
