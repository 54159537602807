import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "../../../services/api-request";
import { toast } from "sonner";

interface PaymentValues {
  lessonId: string;
  marchantId: string;
  timeSlots: Array<{ end: string; start: string }>;
  packageId: string;
  totalPrice: number;
}

interface BalancePaymentRequest {
  token: string;
  values: PaymentValues;
}

interface BalancePaymentResponse {
  [key: string]: any;
}

interface BalancePaymentState {
  data: BalancePaymentResponse | null;
  loading: boolean;
  error: string | null;
}

const initialState: BalancePaymentState = {
  data: null,
  loading: false,
  error: null,
};

export const balancePaymentFn = createAsyncThunk<
  BalancePaymentResponse,
  BalancePaymentRequest,
  { rejectValue: string }
>("balancePayment", async ({ token, values }, { rejectWithValue }) => {
  console.warn({
    lessonId: values.lessonId,
    merchantId: values.marchantId,
    timeSlots: values.timeSlots,
    packageId: values.packageId,
    payment: {
      amount: values.totalPrice,
      paymentType: "userBalance",
      currency: "USD",
    },
  });
  try {
    const { response } = await POST(
      "bookings/lesson",
      {
        lessonId: values.lessonId,
        merchantId: values.marchantId,
        timeSlots: values.timeSlots,
        packageId: values.packageId,
        payment: {
          amount: values.totalPrice,
          paymentType: "userBalance",
          currency: "USD",
        },
      },
      token
    );

    toast.success("Your payment was successful");

    return response;
  } catch (err: any) {
    console.error(err, "Payment error");
    toast.error(err.response?.data?.message || "Unknown error occurred");

    return rejectWithValue(
      err.response?.data?.message || "Unknown error occurred"
    );
  }
});

const balancePaymentSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(balancePaymentFn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        balancePaymentFn.fulfilled,
        (state, action: PayloadAction<BalancePaymentResponse>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        balancePaymentFn.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An unexpected error occurred";
        }
      );
  },
});

export default balancePaymentSlice.reducer;
