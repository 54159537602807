import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { currencyFormat } from "@/utils/function";
import { Icon } from "@/components";
import { IoCard } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useAuth } from "@/contexts/auth-context";
import { balancePaymentFn } from "@/redux/slice/payment";
import { useLessonContext } from "@/contexts/lesson-context";
import { fetchBalance, fetchProfile } from "@/redux/slice/profile/profile";
import { useLanguageContext } from "@/contexts/language-context";

interface PaymentModalProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  balance: number;
}

export const PaymentOptions: React.FC<PaymentModalProps> = ({
  setIsOpen,
  isOpen,
  balance,
}) => {
  const navigate = useNavigate();
  const { loading } = useAppSelector((state) => state.balance);
  const { userBalance } = useAppSelector((state) => state.profileSlice);
  const { show } = useLanguageContext();
  const { token } = useAuth();
  const handleCloseModal = () => setIsOpen(false);
  const { values } = useLessonContext();
  const dispatch = useAppDispatch();

  const balancePayment = () => {
    if (balance < values.totalPrice) {
      toast.warning("Your balance is insufficient, try paying with card");
    } else if (loading) {
      toast.warning("processing, wait a minute");
    } else {
      dispatch(balancePaymentFn({ token: token!, values }));
    }
  };

  const _cardPayment = () => {
    navigate("../teachers/direct-payment");
  };

  useEffect(() => {
    dispatch(fetchBalance({ token: token! }));
  }, [dispatch, token]);

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content-options">
            <button onClick={handleCloseModal} className="modal-close">
              X
            </button>
            <h2>Payment options</h2>
            <p className="total-price">
              <span className="total-profile-text">Total :</span>
              {currencyFormat(values.totalPrice)}
            </p>

            {loading ? (
              <p className="wallet-payment-processing">
                Processing your payment . . .
              </p>
            ) : (
              <div>
                <div
                  onClick={() => balancePayment()}
                  className="options-container"
                >
                  <Icon name="logo" size={30} />
                  <div className="balance-wallet-container">
                    <p className="wallet-text">Pay with Wallet Balance</p>
                    <p className="wallet-balance">
                      {loading
                        ? "..."
                        : show
                        ? "*****"
                        : currencyFormat(userBalance)}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => _cardPayment()}
                  className="options-container"
                >
                  <IoCard size={60} />
                  <p className="wallet-text-card">Pay with card</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
